<!--
 * @Description: 视频设置弹窗
 * @Date: 2021-10-27 18:13:37
 * @LastEditTime: 2021-11-09 15:48:57
-->
<template>
  <div class="setting-item">
    <div class="select-item"><span class="set-title">摄像头</span>
      <Select class="select-style" v-model="choseCameraId" placeholder="请选择摄像头">
        <Option v-for="item in cameraList" :key="item.deviceId" :label="item.label" :value="item.deviceId"></Option>
      </Select>
    </div>
    <!-- <div class="local-stream-preview" id="preview-stream" :class="rotateClass"></div> -->
    <!-- <Checkbox class="mirror-checkbox" v-model="isSetMirror" :disabled="liveStage !== LIVE_STAGE.NOT_STARTED">
      画面反转
    </Checkbox> -->
    <!-- <div class="select-item"><span class="set-title">分辨率</span></span>
      <Select class="select-style" v-model="profileValue" placeholder="请选择分辨率">
        <Option v-for="item, index in profileList" :key="index" :label="item" :value="item"></Option>
      </Select>
    </div> -->
    <!-- <div class="select-item"><span class="set-title">帧率FPS</span>
      <Select class="select-style" v-model="videoSetting.frameRate" placeholder="请选择帧率">
        <Option v-for="item, index in [15, 30]" :key="index" :label="item" :value="item"></Option>
      </Select>
    </div>
    <div class="select-item"><span class="set-title">码率</span>
      <Input-number class="select-style" v-model="videoSetting.bitrate" controls-position="right" :min="1" :max="9000" :step="100"></Input-number>
    </div> -->
  </div>
</template>

<script>
import rtc from '@/components/mixin/rtc';
import { mapGetters, mapState } from 'vuex';

import {
  LIVE_STAGE,
} from 'constants/room';
const profileList = [
  '160*120',
  '320*180',
  '320*240',
  '640*360',
  '640*480',
  '1280*720',
  // '1920*1080',
  // '2560*1440',
  // '3840*2160',
];
export default {
  name: 'compVideoSetting',
  mixins: [rtc],
  props: {
    activeTab: {
      type: String,
      default: '',
    },
    dialogVisible: Boolean,
  },
  data() {
    return {
      LIVE_STAGE,
      cameraList: [],
      choseCameraId: '',
      isSetMirror: false,
      profileList,
      profileValue: '1280*720',
      videoSetting: null,
    };
  },
  computed: {
    ...mapGetters(['activeCameraId']),
    ...mapState({
      liveStage: 'liveStage',
      videoProfile: 'videoProfile',
    }),
    rotateClass() {
      return this.isSetMirror ? 'rotateY-180' : 'rotateY-0';
    },
  },
  watch: {
    videoProfile: {
      immediate: true,
      handler(val, oldVal) {
        if (!oldVal && val) {
          this.videoSetting = val;
          this.profileValue = `${val.width}*${val.height}`;
        }
      },
    },
    videoSetting: {
      deep: true,
      handler(val) {
        this.setVideoProfile(val);
        this.$store.commit('UPDATE_VIDEO_PROFILE', val);
      },
    },
    // 关闭弹窗时销毁视频流，释放设备
    dialogVisible(val) {
      if (!val) {
        this.destroyLocalStream();
      }
    },
    activeCameraId: {
      immediate: true,
      handler(val) {
        this.choseCameraId = val;
      },
    },
    activeTab: {
      immediate: true,
      async handler(val, oldVal) {
        if (oldVal === 'video') {
          this.destroyLocalStream();
        }
        if (val === 'video') {
          this.localStream = await this.initLocalStream({
            audio: false,
            video: true,
            cameraId: this.choseCameraId,
            mirror: this.isSetMirror,
          });
          this.playStream(this.localStream, 'preview-stream');
        }
      },
    },
    choseCameraId(val) {
      this.switchDevice('video', val);
      const choseCameraDevice = this.cameraList.find(item => item.deviceId === val);
      this.$store.commit('UPDATE_ACTIVE_CAMERA', choseCameraDevice);
    },
    isSetMirror(val) {
      this.$store.commit('UPDATE_SET_MIRROR', val);
    },
    profileValue(val) {
      const [width, height] = val.split('*');
      this.videoSetting.width = parseInt(width, 10);
      this.videoSetting.height = parseInt(height, 10);
    },
  },
  methods: {
    async getDeviceList() {
      this.cameraList = await this.getCameras();
      const cameraIDList = this.cameraList.map(camera => camera.deviceId);
      if (cameraIDList.indexOf(this.choseCameraId) < 0) {
        this.choseCameraId = this.cameraList[0].deviceId;
      }
    },
  },
  created() {
    navigator.mediaDevices.addEventListener('devicechange', async () => {
      await this.getDeviceList();
    });
    this.getDeviceList();
  },
  mounted() {
  },
};
</script>

<style lang="stylus" scoped>
.setting-item
  padding 10px 25px
  .select-item
    margin-bottom 20px
    
    .select-style
      width 240px
      flex-grow 1

  .rotateY-180
    & >>> div > video
      transform rotateY(180deg) !important
  .rotateY-0
    & >>> div > video
      transform rotateY(0deg) !important
  .mirror-checkbox
    margin-bottom 10px
</style>

