<!--
 * @Description: 视频流区域
 * @Date: 2021-09-22 16:40:01
 * @LastEditTime: 2021-11-01 15:32:38
-->
<template>
  <div class="pusher-rtc-container" id="room-container">
    <comp-stream-pusher class="stream-pusher"></comp-stream-pusher>
    <comp-stream-control class="stream-control"></comp-stream-control>
  </div>
</template>

<script>
import compStreamControl from './comp-stream-control';
import compStreamPusher from './comp-stream-pusher';
export default {
  name: 'PusherRTC',
  components: {
    compStreamControl,
    compStreamPusher,
  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style lang="stylus" scoped>
.pusher-rtc-container
  position relative
  width 100%
  height calc(100vh - 80px)
  .stream-pusher
    position relative
    width 100%
    height 100%
  .stream-control
    position absolute
    top 0
    left 0
</style>

