<template>
    <Modal class="comp-pack game-modal" footer-hide width="400" v-model="isDisplay" >
    <div class="title">
        发红包
    </div>
        <div class="content">
            <Form :model="form" :label-width="80">
                <FormItem>
                    <RadioGroup v-model="form.redpack_type" class="type" type="button">
                        <Radio label="2">手气红包</Radio>
                        <Radio label="1">等额红包</Radio>
                    </RadioGroup>
                </FormItem>
                <div v-show="form.redpack_type == '1'">
                    <FormItem label="金额">
                        <Input v-model="form.single_amount" placeholder="单个红包金额"></Input>
                    </FormItem>
                    <FormItem label="红包数量">
                        <Input v-model="form.redpack_number" placeholder="红包数量"></Input>
                    </FormItem>
                     <FormItem label="中奖概率">
                        <Input v-model="form.win_probability" placeholder="中奖概率 百分比%"></Input>
                    </FormItem>
                </div>

                <div v-show="form.redpack_type == '2'">
                    <FormItem label="金额">
                        <Input v-model="form.total_amount" placeholder="红包总金额"></Input>
                    </FormItem>
                    <FormItem label="红包数量">
                        <Input v-model="form.redpack_number" placeholder="红包数量"></Input>
                    </FormItem>
                    <FormItem label="中奖概率">
                        <Input v-model="form.win_probability" placeholder="中奖概率 百分比%"></Input>
                    </FormItem>
                </div>
                
                <Button type="primary" @click="createdAndSend()">
                    立即发送
                </Button>

            </Form>
        </div>
    </Modal>
</template>

<script>
    export default {
        name: 'MashPushPack',
        data() {
            return {
                isDisplay:false,
                form:{
                    redpack_name:'红包',
                    start_time:'',//开始时间，立即开始 不传值
                    redpack_type:'2',//红包类型(1:固定红包;2:随机红包)
                    duration:'20',//持续时间(s)
                    win_probability:'5', //中奖概率(百分比)
                    win_number:'1', //最多领取次数
                    redpack_number:'', //红包个数
                    single_amount:'', //单个红包金额(红包类型为(1)固定金额时使用)
                    total_amount:'',  //红包总金额(红包类型为(2)随机红包时使用)
                }
            };
        },
        mounted() {
            
        },
        methods: {
            display(){
                this.isDisplay = !this.isDisplay
            },
            createdAndSend(){


                if(!this.form.single_amount && this.form.redpack_type == '1'){
                    this.$Message.warning('请输入单个红包金额!');
                }

                if(!this.form.total_amount && this.form.redpack_type == '2'){
                    this.$Message.warning('请输入红包总金额!');
                }

                if(!this.form.redpack_number){
                    this.$Message.warning('请输入红包数量数!');
                }

                if(!this.form.win_probability){
                    this.$Message.warning('请输入请输入中奖概率!');
                }

                this.form.redpack_name = '红包-' + this.$util.date();
                this.form.start_time = this.$util.date();
                this.form.room_id = this.$route.query.id,


                this.$api.addPacket(
                    this.form
                ).then((res) => {
                    if (res.code >= 0) {
                        console.log(res);
                        console.log('创建成功');

                        this.$api.sendPacket({
                            room_id:this.$route.query.id,
                            redpack_id:res.data
                        }).then((sendRes) => {
                            if (sendRes.code >= 0) {
                                this.display()
                                this.$Message.success('发送成功');
                                console.log(sendRes);
                                console.log('发送成功');
                            }else {
                                this.$Message.error(res.message);
                            }
                        });
                    }
                });
            },
        },
    };
</script>
<style lang="scss" scoped>

</style>