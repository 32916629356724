<template>
  <div class="setting-comp">
          <div class="title">音频设置</div>
          <audio-setting></audio-setting>
          <div class="title">视频设置</div>
          <video-setting></video-setting>
          <!-- <div class="title">美颜设置</div> -->
          <!-- <beauty-setting></beauty-setting> -->
  </div>
</template>

<script>
import audioSetting from './audio-setting';
import videoSetting from './video-setting';
// import beautySetting from './beauty-setting';
export default {
  name: 'liveSetting',
  data() {
    return {
      dialogVisible: false,
      activeTab: '',
      cameraList: [],
      choseCameraId: '',
      microphoneList: [],
      choseMicrophoneId: '',
      speakerList: [],
      choseSpeakerId: '',
      localStream: null,
      isOpenBeauty: true,
    };
  },
  components: {
    audioSetting,
    videoSetting,
    // beautySetting,
  },
  computed: {
  },
  methods: {
   
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.button-container{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.live-setting-dialog{
  text-align: left;
}
</style>
