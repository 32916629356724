<template>
  <Modal class="dialog-style-title dialog-style"  v-model="showDeviceDialog" width="460px" :before-close="handleClose" :close-on-click-modal="false">
    <div class="dialog-title" slot="title">设备设置</div>
    <div class="dialog-content">
      <span class="title">设备设置</span>
      <div class="checkbox-container">
        <Checkbox class="checkbox" v-model="openCamera">视频</Checkbox>
        <Checkbox class="checkbox" v-model="openBeauty">美颜</Checkbox>
        <Checkbox class="checkbox" v-model="openRecord">录制</Checkbox>
      </div>
      <span class="title">设备选择</span>
      <div class="select-container">
        <div class="microphone-select">
          <span class="title">麦克风</span>
          <device-select deviceType="microphone"></device-select>
        </div>
        <div class="speaker-select">
          <span class="title">扬声器</span>
          <device-select deviceType="speaker"></device-select>
        </div>
        <div class="camera-select">
          <span class="title">摄像头</span>
          <device-select deviceType="camera"></device-select>
        </div>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <Button type="primary" @click="handleNextStep">{{ openBeauty ? '下一步' : '完成设置' }}</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import DeviceSelect from '@/components/common/device-select';
export default {
  name: 'roomDeviceDialog',
  data() {
    return {
      openCamera: true,
      openBeauty: false,
      openRecord: false,
      settingType: '',
      showDeviceDialog: false,
      inputName: '',
    };
  },
  components: {
    DeviceSelect,
  },
  computed: {
    ...mapState({
      roomName: 'roomName',
      isVideoMuted: 'isVideoMuted',
      isOpenBeauty: 'isOpenBeauty',
      isRecordLive: 'isRecordLive',
    }),
  },
  watch: {
    isVideoMuted: {
      immediate: true,
      handler(val) {
        this.openCamera = !val;
      },
    },
    isOpenBeauty: {
      immediate: true,
      handler(val) {
        this.openBeauty = val;
      },
    },
    isRecordLive: {
      immediate: true,
      handler(val) {
        this.openRecord = val;
      },
    },
    openCamera(val) {
      this.$store.commit('UPDATE_VIDEO_STATE', !val);
    },
    openBeauty(val) {
      this.$store.commit('UPDATE_OPEN_BEAUTY', val);
    },
  },
  methods: {
    handleShowDeviceDialog() {
      this.showDeviceDialog = true;
    },
    handleNextStep() {
      this.showDeviceDialog = false;
      if (this.openBeauty) {
        this.$emit('nextStep');
      }
    },
    handleClose() {
      this.showDeviceDialog = false;
    },
    handleSure() {
      this.handleClose();
      this.$store.commit('UPDATE_ROOM_NAME', this.inputName);
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-title
  font-weight bold
  color #616472
  font-size 16px
.dialog-content
  padding 0 10px
  text-align left
  .title
    font-weight bold
    color #616472
    font-size 16px
    display inline-block
    margin-bottom 24px
  .checkbox-container
    width 100%
    margin-bottom 14px
    .checkbox
      margin-bottom 10px
  .select-container
    .title
      width 60px
      font-weight 500
.dialog-footer
  width 100%
  height 100%
  text-align center
</style>

