<!--
 * @Description: 结束直播按钮
 * @Date: 2021-10-27 21:44:09
 * @LastEditTime: 2021-11-09 15:43:57
-->
<template>
  <div class="btn-container">
    <Button type="error" size="large" :disabled="stopLiveDisabled" @click="showStopLiveDialog = true" >结束直播</Button>
    <Modal class="dialog-style" v-model="showStopLiveDialog" width="340px" center="center" append-to-body="append-to-body">
    <span class="dialog-title" slot="title">确定结束吗？</span>
      <div class="content-info">确定结束吗？</div><span class="dialog-footer" slot="footer">
        <Button @click="showStopLiveDialog = false">确定</Button>
        <Button type="primary" @click="stopLive">取消</Button>
      </span>
    </Modal>
  </div>
</template>

<script>
import { LIVE_STAGE } from 'constants/room';

import { mapState } from 'vuex';
export default {
  name: 'compEndBtn',
  data() {
    return {
      showStopLiveDialog: false,
    };
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
    }),
    stopLiveDisabled() {
      return this.liveStage === LIVE_STAGE.NOT_STARTED || this.liveStage === LIVE_STAGE.ENDED;
    },
    buttonType() {
      return this.liveStage === LIVE_STAGE.NOT_STARTED || this.liveStage === LIVE_STAGE.ENDED ? 'info' : 'primary';
    },
  },
  methods: {
    stopLive() {
      this.showStopLiveDialog = false;
      this.$store.commit('UPDATE_LIVE_STAGE', LIVE_STAGE.ENDED);
      this.$eventBus.$emit('exit');
    },
  },
};
</script>

<style lang="stylus" scoped>
.content-info
  text-align left
</style>
