<template>
    <Modal class="comp-pwd game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            设置门票
        </div>
        <div class="content">
            <Form :model="form" :label-width="80">
                <FormItem label="打开门票">
                     <i-switch v-model="form.state" size="large">
                        <span slot="open">打开</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>

                <FormItem label="门票商品" v-show="form.state">
                    <Select @on-change="selectChange" placeholder="请选择商品作为门票">
                        <Option v-for="(item,index) in goods_list.list" :value="item.goods_id" :key="index">
                            <img class="img" :src="$util.handleUrl(item.goods_image)" >
                            <font class="name">{{ item.goods_name }}</font>
                            <font class="price">￥{{item.price}}</font>
                        </Option>
                    </Select>
                </FormItem>

                <Button type="primary" @click="setViewConditionSetting()">
                    保存
                </Button>
            </Form>
        </div>
    </Modal>
</template>

<script>
export default {
    name: 'MashPushTicket',
    data() {
        return {
            isDisplay:false,
            form:{
                goods_id:'',
                state:false,
            },
            viewCondition:{

            },
            goods_list:{

            },
        };
    },
    mounted() {
    },
    created(){
        this.getGoodsList();
        this.getviewConditionSetting()
    },
    methods: {
        display(){
            this.isDisplay = !this.isDisplay
        },
         //获取商品列表
        getGoodsList() {
            console.log(this.$route.query.id);
            this.$api.getGoodsList({
                page: 1,
                page_size: 100, 
                room_id:this.$route.query.id
            }).then((res) => {
                if (res.code >= 0) {
                    this.goods_list = res.data
                }
            });
        },
        // 获取观看条件设置
        getviewConditionSetting(){
             this.$api.getviewConditionSetting({
                room_id:this.$route.query.id,
            }).then((res) => {
                if (res.code >= 0) {
                    this.viewCondition = res.data
                    if(res.data !== ''){
                        this.form.goods_id = res.data.view_condition_setting.goods_id
                        this.form.state = true
                    }
                }
            });
        },
        // 设置门票
        setViewConditionSetting(){

            if (this.form.state == true && this.form.goods_id == ''){
                this.$Message.warning('请选择门票！');
                console.log('设置门票 但没选择')
                return;
            }else if(this.form.state == false || this.form.goods_id == ''){
                console.log('不设门票')
                this.form.goods_id =''
                this.viewCondition.view_condition = 1
            }else if (this.form.state == true && this.form.goods_id !== '') {
                console.log('设置门票2')
                 this.viewCondition.view_condition = 2
            }

            console.log(this.$route.query.id);
            this.$api.setViewConditionSetting({
                room_id:this.$route.query.id,
                type:this.viewCondition.view_condition,
                goods_id:this.form.goods_id
            }).then((res) => {
                if (res.code >= 0) {
                    this.display()
                    this.$Message.success('设置成功');
                }else {
                    this.$Message.error(res.message);
                }
            });
        },

        //选中变更
        selectChange(data){
            this.form.goods_id = data
        }
    },
};
</script>
<style lang="scss" scoped>
.ivu-select-item {
    vertical-align: middle;
}
.ivu-select-item .img {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-right: 10px;
}
.ivu-select-item .name {
    line-height: 30px;
    vertical-align: top;

}
.ivu-select-item .price {
    color:#ed4014;
    float: right;
    line-height: 30px;
    vertical-align: top;
}
</style>