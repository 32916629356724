<template>
    <Modal class="comp-hot game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            虚拟人气
        </div>
        <div class="content">
             <Form :model="form" :label-width="80">

                <FormItem label="起始" >
                    <Input v-model="form.start" placeholder="从多少开始"></Input>
                </FormItem>

                <FormItem label="最高" >
                    <Input v-model="form.end" placeholder="到多少结束"></Input>
                </FormItem>

                <Button type="primary" @click="setFakePersonSetting('formValidate')">
                    保存
                </Button>
            </Form>
        </div>
    </Modal>
</template>

<script>
export default {
    name: 'MashPushHot',
    data() {
        return {
            isDisplay:false,
            form:{
                start:0,
                end:0,
            },

        };
    },
    mounted() {
    },
    created(){
        //获取虚拟人气设置
        this.getFakePersonSetting()
    },
    methods: {
        display(){
            this.isDisplay = !this.isDisplay
        },
        // 获取虚拟人气设置
        getFakePersonSetting(){
             this.$api.getfakePersonSetting({
                room_id:this.$route.query.id,
            }).then((res) => {
                if (res.code >= 0) {
                    this.form.start = res.data.fake_person_setting.number
                    this.form.end = res.data.fake_person_setting.target_number
                }
            });
        },
        // 设置保存虚拟人气
        setFakePersonSetting(){
            console.log(this.$route.query.id);
            this.$api.setFakePersonSetting({
                room_id:this.$route.query.id,
                number:this.form.start,
                target_number:this.form.end,
                execute:1
            }).then((res) => {
                if (res.code >= 0) {
                    this.display()
                    this.$Message.success('设置成功');
                }
            });
        }
    },
};
</script>
<style lang="scss" scoped>
    
</style>